export const __ServiceInfo = {
    sale: 'فروش',
    share: 'اشتراک گذاری',
    title_desc: 'آنچه بعد از خرید مستقیم از این برند برای این خدمت، از تهرانتو کلاب دریافت می کنید:',
    refund: 'دلار بازگشت وجه',
    refund_percent: 'درصد بازگشت وجه',
    receive_services: 'استفاده از این آفر',
    create_service_success: 'پیش سفارش شما با موفقیت ثبت شد.',
    create_service_error: 'درخواست خرید ناموفق بود.',
    copy_alert: 'لینک خدمت با موفقیت در کلیپ بورد شما ذخیره شد.',
    selected: 'انتخاب شده',
};
