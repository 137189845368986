'use client';
import Link from 'next/link';
import { CSSProperties, MouseEventHandler, PropsWithChildren, useEffect, useRef, useState } from 'react';
import LoadingComp from 'tehranto/loading/loading.index';
import { Portal } from 'react-portal';
import { nextBrowser } from '@/scripts';
import { useParams } from 'next/navigation';

const __AppLinkComp: React.FC<
    PropsWithChildren<{
        href: string;
        className?: string;
        style?: CSSProperties;
        onClick?: MouseEventHandler<HTMLAnchorElement>;
        onMouseOver?: MouseEventHandler<HTMLAnchorElement>;
        searchParamsSensitive?: boolean;
        preventReload?: boolean;
    }>
> = (props) => {
    const searchParamsSensitive = props.searchParamsSensitive === false ? false : true;
    const [loading, setLoading] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const params = useParams();
    const onClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        if (urlDiff()) {
            if (props.preventReload) {
                e.preventDefault();
                e.stopPropagation();
            } else setLoading(true);
        }
    };
    const urlDiff = () => {
        if (ref.current) {
            const current = new URL(nextBrowser.window?.location.href ?? '');
            const next = new URL(ref.current.href);
            const str1 = next.protocol + next.host + next.pathname + (searchParamsSensitive ? next.search : '');
            const str2 =
                next.protocol + current.host + current.pathname + (searchParamsSensitive ? current.search : '');
            if (str1 !== str2) return true;
        }
        return false;
    };
    useEffect(() => {
        setLoading(false);
    }, [params]);
    return (
        <>
            <Link
                href={props.href}
                ref={ref}
                className={props.className}
                style={props.style}
                onClick={(e) => {
                    if (props.onClick) props.onClick(e);
                    onClickHandler(e);
                }}
                onMouseOver={props.onMouseOver}
            >
                {props.children}
            </Link>

            {loading ? (
                <Portal>
                    <LoadingComp />
                </Portal>
            ) : null}
        </>
    );
};

export default __AppLinkComp;
