import { __theme } from './antd/antd';

export const COLORS = {
    primary: '#009de0',
    blue2: '#00b9e4',
    blue3: '#00d1d1',
    green: '#36e5ae',
    dark1: '#222a2e',
    dark2: '#474f54',
    dark3: '#6f787d',
    dark4: '#9aa4a9',
    dark5: '#c7d1d6',
    dark6: '#f1f3f5',
    fieldBG: '#f9fafb',
    red: '#f44336',
    orange: '#e59436',
    yellow: '#f2ae00',
    darkGreen: '#00b149',
};

export { __theme as AntdTheme };
