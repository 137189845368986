'use client';
import styles from './housingFilter.module.scss';
import { FaFilter } from 'react-icons/fa6';
import { GoTrash } from 'react-icons/go';
import { Form, Select, Input, Checkbox, Drawer, Button } from 'antd';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import { LanguageEnum } from '@/types';
import { __InitialFilterValues } from './housingFilter.type';
import { __BuildingTypeData, __StayTypeData } from './housingFilter.data';

export function __HousingFilterComp({
    isOpen,
    setIsOpen,
    filterValues,
    setFilterValues,
}: {
    isOpen: boolean;
    setIsOpen: (e: boolean) => void;
    filterValues: __InitialFilterValues;
    setFilterValues: (e: __InitialFilterValues) => void;
}) {
    const lang = useContext(GlobalContext).langText.housingFilter;
    const langText = useContext(GlobalContext).langText.lang;
    const [form] = Form.useForm();
    const genderOptions = [
        { value: 'female', label: lang.female },
        { value: 'male', label: lang.male },
        { value: 'all', label: lang.all },
    ];

    const valueHandler = (e: __InitialFilterValues) => {
        setFilterValues({ ...filterValues, ...e });
    };

    const filterContent = (
        <Form layout="vertical" form={form} onFinish={(e) => valueHandler(e)} initialValues={filterValues}>
            <div className="d-flex justify-content-between mb-3">
                <h4 className="t-700 t-h4 t-dark1 d-flex center-content gap-8">
                    <FaFilter size={18} /> {lang.filters}
                </h4>
                <Button
                    htmlType="reset"
                    type="text"
                    onClick={() => setFilterValues({})}
                    className="t-400-i t-p1-i t-dark3-i d-flex-i center-content gap-8 pointer"
                >
                    <GoTrash size={18} /> {lang.delete}
                </Button>
            </div>
            <div className={`${styles['houses-type-layout']} mb-2`}>
                <Form.Item name="rent" label={<p className="t-p2 t-500 t-dark2">{lang.lease}</p>}>
                    <Checkbox.Group
                        options={[
                            { label: lang.sharedRental, value: 'shared' },
                            { label: lang.independentRental, value: 'independent' },
                        ]}
                        className="flex-column gap-8 m-0-i"
                    />
                </Form.Item>
            </div>
            <div className={`${styles['price-age-layout']} mb-2`}>
                <p className="t-p2 t-500 t-dark2">{lang.monthlyFee}</p>
                <div className="center-content gap-8">
                    <Form.Item name="min_price">
                        <Input type="number" placeholder={`${lang.min} CAD`} />
                    </Form.Item>
                    <p className="t-p2 t-500 t-dark2">{lang.fromText}</p>
                    <Form.Item name="max_price">
                        <Input type="number" placeholder={`${lang.max} CAD`} />
                    </Form.Item>
                </div>
            </div>
            <div className={`${styles['price-age-layout']} mb-2`}>
                <p className="t-p2 t-500 t-dark2">{lang.rang}</p>
                <div className="center-content gap-8">
                    <Form.Item name="min_age">
                        <Input type="number" placeholder={lang.min} />
                    </Form.Item>
                    <p className="t-p2 t-500 t-dark2">{lang.fromText}</p>
                    <Form.Item name="max_age">
                        <Input type="number" placeholder={lang.max} />
                    </Form.Item>
                </div>
            </div>
            <div className={`${styles['gender-layout']} mb-2`}>
                <Form.Item name="host_gender" label={<p className="t-p2 t-500 t-dark2">{lang.residentGender}</p>}>
                    <Select placeholder={lang.selectPlaceholder} options={genderOptions} />
                </Form.Item>
            </div>
            <div className={`${styles['gender-layout']} mb-2`}>
                <Form.Item name="roommate_gender" label={<p className="t-p2 t-500 t-dark2">{lang.roommateGender}</p>}>
                    <Select placeholder={lang.selectPlaceholder} options={genderOptions} />
                </Form.Item>
            </div>
            <div className={`${styles['building-type-layout']} mb-2`}>
                <Form.Item name="building_type" label={<p className="t-p2 t-500 t-dark2">{lang.buildingType}</p>}>
                    <Checkbox.Group options={__BuildingTypeData} className="flex-column gap-8" />
                </Form.Item>
            </div>
            <div className={`${styles['stay-type-layout']}`}>
                <Form.Item name="stay_type" label={<p className="t-p2 t-500 t-dark2">{lang.type}</p>}>
                    <Checkbox.Group options={__StayTypeData(lang)} className="flex-column gap-8" />
                </Form.Item>
            </div>
            <Button htmlType="submit" className={`${styles['filter-btn']} w-100 mt-2-i`}>
                {lang.filter}
            </Button>
        </Form>
    );

    return (
        <>
            <div className={`${styles['filter-layout']} w-100 d-pre-md-none`}>{filterContent}</div>
            <Drawer
                placement={langText === LanguageEnum.en ? 'left' : 'right'}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                width="100%"
                className={`${styles['header-drawer']} d-md-none`}
            >
                <div className={`${styles['filter-layout']} w-100`}>{filterContent}</div>
            </Drawer>
        </>
    );
}
