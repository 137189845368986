export const __ServiceDetailLang = {
    filters: 'فیلتر ها',
    other_services: 'سایر خدمات',
    mostPriority: 'منتخب',
    newest: 'جدیدترین',
    oldest: 'قدیمی ترین',
    most_visited: 'پربازدید ترین',
    most_popular: 'محبوب ترین',
    maximum_cashback: 'بیشترین بازگشت وجه',
    ordering: 'مرتب سازی',
    result: 'نتیجه',
    customers_comments: 'نظرات مشتریان',
    comments_service: 'نظر درباره خدمت',
    terms_of_use: 'شرایط استفاده',
    descriptions: 'توضیحات',
    errorMessage: 'درخواست با خطا مواجه شد.',
    empty: 'خدمتی با فیلتر مورد نظر یافت نشد.',
};
