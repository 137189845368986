'use client';
import Image from 'next/image';
import { Button, Spin } from 'antd';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { LanguageEnum } from '@/types';
import { useKeenSlider } from 'keen-slider/react';
import { useBP } from '@/scripts';
import 'keen-slider/keen-slider.min.css';
import styles from './gallery.module.scss';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/context';
import { AttachmentType } from '@/types';

function GalleryComp({ data, className }: { data?: AttachmentType[]; className?: string }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const bp = useBP();
    const langText = useContext(GlobalContext).langText.lang;
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
        {
            rtl: langText === LanguageEnum.fa,
            loop: true,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
            },
            created() {
                setLoaded(true);
            },
        },
        [
            (slider) => {
                let timeout: ReturnType<typeof setTimeout>;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 3000);
                }
                slider.on('created', () => {
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ],
    );

    const firstIcon = langText === LanguageEnum.fa ? <FaArrowRightLong /> : <FaArrowLeftLong />;
    const secondIcon = langText === LanguageEnum.fa ? <FaArrowLeftLong /> : <FaArrowRightLong />;

    useEffect(() => {
        instanceRef.current?.update();
        if (bp) setLoading(false);
    }, [bp?.bp]);

    return (
        <div className={`${styles['navigation-wrapper']} ${className ?? ''}`}>
            {loading ? (
                <div className={`${styles['loading']} d-flex center-content`}>
                    <Spin className="d-flex-i center-content" />
                </div>
            ) : (
                <div ref={sliderRef} className={`${styles['slider']} keen-slider`}>
                    {data &&
                        data.map((file, index) => (
                            <div key={index} className="keen-slider__slide number-slide1">
                                {['MP4', 'WebM', 'AVI', 'WMV', 'MKV'].find((i) =>
                                    file.file.toUpperCase().endsWith(i),
                                ) ? (
                                    <video autoPlay={true} muted={true} controls={false} loop={true} src={file.file} />
                                ) : (
                                    <Image
                                        alt={file.alt ?? ''}
                                        width={550}
                                        height={550}
                                        src={file.file}
                                        className="w-100 d-block-i"
                                    />
                                )}
                            </div>
                        ))}
                </div>
            )}
            {loaded && instanceRef.current && (
                <>
                    <div className={`${styles['btn-slider']} w-100 d-flex justify-content-between align-items-center`}>
                        <Button type="primary" onClick={() => instanceRef.current?.prev()}>
                            {firstIcon}
                        </Button>

                        <Button type="primary" onClick={() => instanceRef.current?.next()}>
                            {secondIcon}
                        </Button>
                    </div>
                    <div className={styles['shadow']} />
                    <div className={`${styles['dots']} w-100 d-flex justify-content-between`}>
                        {data?.map((i, index) => (
                            <div
                                key={index}
                                onClick={() => instanceRef.current?.moveToIdx(index)}
                                className={`${styles['dot']}`}
                            >
                                {currentSlide === index ? (
                                    <span
                                        className={`${styles['active']} ${langText === LanguageEnum.fa ? styles['rtl-timeline'] : ''}`}
                                    ></span>
                                ) : null}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default GalleryComp;
