'use client';
import styles from './successModal.module.scss';
import Subtract from 'assets/images/Subtract2.svg';
import { PATH } from 'data';
import { CacheBackTypeEnum } from 'types';
import Image from 'next/image';
import { Fragment, useContext } from 'react';
import { GlobalContext } from 'context';
import { Button, Modal } from 'antd';
import { RiDiscountPercentFill } from 'react-icons/ri';
import { COLORS } from 'utilities';
import { __SuccessOfferBuyModalPropsType } from './successOfferBuyModal.type';

export default function SuccessOfferBuyModalComp(props: __SuccessOfferBuyModalPropsType) {
    const { langText } = useContext(GlobalContext);
    const lang = langText.components;
    const data = props.data;

    const benefits =
        (data.cash_back
            ? `${data.cash_back} ${data.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%'}  ${data.cash_back_label ?? lang.refund}`
            : '') + (data.extra_service?.length ? ' + ' + data.extra_service.join(' + ') : '');

    const onPrint = () => {
        if (props.onPrint) props.onPrint();
    };

    const footerContent = [
        <Button key="confirm" className="ph-3-i" type="primary" onClick={props.onClose}>
            {lang.confirm}
        </Button>,
        <Button key="print" className="ph-2-i" onClick={onPrint} loading={props.printLoading}>
            {lang.download}
        </Button>,
    ];

    const content = (
        <>
            <div className="gap-16 mt-1">
                <RiDiscountPercentFill size={28} color={COLORS.green} />
                <span>{lang.initializeSuccess}</span>
            </div>
            <div className={`${styles['service-modal']} d-flex justify-content-between mb-3 gap-32`}>
                <div className={`${styles['info']}`}>
                    <p className="mt-2">
                        {lang.initialDescription.map((i, j) => (
                            <Fragment key={j}>
                                {i === 'brand' ? (
                                    <a
                                        className="t-primary"
                                        href={PATH(langText.lang).brand(data.brand.slug)}
                                        target="_blank"
                                    >
                                        {' '}
                                        {data.brand.title}
                                    </a>
                                ) : i === 'link' ? (
                                    <a className="t-primary" href={PATH(langText.lang).panel.orders()} target="_blank">
                                        {' '}
                                        {lang.initialLink}
                                    </a>
                                ) : i === 'benefits' ? (
                                    <span className="t-500"> {benefits}</span>
                                ) : (
                                    <span> {i}</span>
                                )}
                            </Fragment>
                        ))}
                    </p>
                    <p>{lang.initialWarning}</p>
                    {data.brand.address ? (
                        <div className="mt-3">
                            <p className="t-dark1 t-700 t-p1 mb-1">{lang.address}</p>
                            <p className="t-p1 t-400 t-dark2">{data.brand.address}</p>
                        </div>
                    ) : null}

                    {data.brand.phone ? (
                        <div className="mt-2">
                            <p className="t-dark1 t-700 t-p1 mb-1">{lang.phoneNumber}</p>
                            <p className={`t-p1 t-400 ${styles['phone']}`}>
                                <a className="t-primary" href={`tel:${data.brand.phone.split(' ').join('')}`}>
                                    {data.brand.phone}
                                </a>
                            </p>
                        </div>
                    ) : null}
                </div>
                <div className={`${styles['qr-code']}`}>
                    {props.order?.qr_code ? <img src={props.order.qr_code?.file} alt="qr code" /> : null}
                </div>
            </div>
            <div className={`${styles['service-items']} align-items-center justify-content-between pv-2 ph-3`}>
                <div>
                    <p className="t-400 t-p1 t-primary">{lang.title_desc}</p>
                    <ul className="ph-3 t-primary">
                        <li className={`t-700 t-h4 t-primary ${!data.cash_back ? 'd-none' : ''}`}>
                            {data.cash_back}
                            {data.cash_back_type === CacheBackTypeEnum.constant ? '$' : '%'}{' '}
                            {data.cash_back_type === CacheBackTypeEnum.constant ? lang.refund2 : lang.refund_percent}
                        </li>
                        {data.extra_service?.map((service, index) => (
                            <li key={index} className="t-700 t-h4">
                                {service}
                            </li>
                        ))}
                    </ul>
                </div>
                <Image src={Subtract} alt="Subtract" width={95} height={95} />
            </div>
        </>
    );

    if (props.print) return <>{content}</>;

    return (
        <Modal
            open={props.open}
            onCancel={props.onClose}
            width={900}
            closable={false}
            maskClosable
            footer={footerContent}
        >
            {content}
        </Modal>
    );
}
