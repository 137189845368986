export const __EventListLang = {
    newest: 'جدیدترین',
    most_visited: 'پربازدید ترین',
    most_popular: 'محبوب ترین',
    oldest: 'قدیمی ترین',
    ordering: 'مرتب سازی',
    result: 'نتیجه',
    events: 'دورهمی ها',
    about: 'درباره',
    desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.',
    filters: 'فیلتر',
    placeholder: 'جستجو در رویداد ها ...',
};
