'use client';
import React from 'react';
import { ConfigProvider } from 'antd';
import { ContextProviderComp } from 'context';
import { AntdTheme } from 'utilities';
import { LanguageEnum } from 'types';
import faIR from 'antd/locale/fa_IR';
import styles from './base.module.scss';
import HeaderComp from '../header/header.index';
import FooterComp from '../footer/footer.index';
import { __BasePropsType } from './base.type';
import BottomNavigationComp from '../bottomNavigation/bottomNavigation.index';

export const __BaseComp: React.FC<React.PropsWithChildren<__BasePropsType>> = (
    props: React.PropsWithChildren<__BasePropsType>,
) => {
    return (
        <ContextProviderComp lang={props.lang} profile={props.profile} blogPost={props.blogPost}>
            <ThemeComp lang={props.lang}>
                {props.noHeader ? null : <HeaderComp hasSlug={props.hasSlug} />}
                <main
                    style={{ backgroundColor: props.backgroundColor }}
                    className={`${styles['container']} flex-column ${
                        props.noHeader && props.noFooter
                            ? styles['no-layout']
                            : props.noFooter
                              ? styles['no-footer']
                              : props.noHeader
                                ? styles['no-header']
                                : ''
                    } `}
                >
                    {props.children}
                </main>
                {props.noFooter ? null : <FooterComp />}
                {!props.noBottomNavigation ? <BottomNavigationComp /> : null}
            </ThemeComp>
        </ContextProviderComp>
    );
};

export const ThemeComp: React.FC<React.PropsWithChildren<{ lang: LanguageEnum }>> = (props) => {
    return (
        <ConfigProvider
            direction={props.lang === LanguageEnum.fa ? 'rtl' : 'ltr'}
            locale={props.lang === LanguageEnum.fa ? faIR : undefined}
            theme={AntdTheme(props.lang)}
        >
            {props.children}
        </ConfigProvider>
    );
};
