export const __MyHostsLang = {
    title: 'میزبانی های من',
    top_btn: 'برگزاری ایونت',
    title_col: 'عنوان ایونت',
    date_col: 'تاریخ',
    status_col: 'وضعیت',
    detail_col: 'جزئیات',
    event_detail: 'جزئیات ایونت',
    view_link: 'مشاهده',
    return: 'بازگشت',
    pending: 'در انتظار برگزاری',
    held: 'برگزار شده',
    empty: 'شما تا اکنون ایونتی را برگزار نکرده اید. برای ساخت اولین ایونت کلیک کنید',
};
