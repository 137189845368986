export const __MyParticipatesLang = {
    title: 'مشارکت های من',
    title_col: 'عنوان ایونت',
    date_col: 'تاریخ',
    status_col: 'وضعیت',
    detail_col: 'جزئیات',
    event_detail: 'جزئیات ایونت',
    view_link: 'مشاهده',
    return: 'بازگشت',
    awaiting_host: 'در انتظار تایید میزبان',
    rejected: 'رد شده',
    pending: 'در انتظار برگزاری',
    held: 'برگزار شده',
    empty: 'شما در ایونتی شرکت نکرده اید.',
};
