import {
    BodyShapeEnum,
    CalmnessEnum,
    CookingEnum,
    EntertainmentEnum,
    HeightEnum,
    JobEnum,
    JobTypeEnum,
    MBTIEnum,
    PersonalInfoEnum,
    SleepEnum,
    StayEnum,
    TravelEnum,
} from 'types';
import { __MBTIMatchEnum } from './dating.type';

export const __DatingLang = {
    dating: 'دوستیابی',
    extroverted: 'برونگرا',
    sensing: 'رویاپرداز',
    intuition: 'واقع بین',
    thinking: 'منطقی',
    intuitive: 'شهودی',
    feeling: 'احساس',
    judgmental: 'قضاوتی',
    introverted: 'درونگرا',
    perceiving: 'برنامه ریز',
    property: 'ویژگی های شخصیتی',
    lifeStyle: 'سبک زندگی',
    year: 'ساله',
    filters: 'فیلتر ها',
    delete: 'حذف',
    search: 'جست و جو',
    information: 'اطلاعات تکمیلی',
    previous: 'قبلی',
    next: 'بعدی',
    sendMessage: 'ارسال پیام',
    save: 'نشان کردن',
    rejected: 'رد کردن',
    cancel: 'انصراف',
    buyLuck: 'خرید شانس',
    successMessage1: 'شما یک شانس آشنایی برای این کاربر استفاده کردید.',
    successMessage2: (id: string) => `آی دی تلگرام کاربر: ${id}`,
    startMessaging: 'ارسال پیام در تلگرام',
    textModal:
        ' تعداد شانس های آشنایی شما تا تاریخ 21/3/2024 به پایان رسده است. می توانید با خرید شانس، پیام ارسال    کنید و یا پس از تاریخ مذکور با افزایش خودکار شانس های شما، مجددا اقدام نمایید.',
    filterApply: 'اعمال فیلتر',
    noData: 'پروفایلی یافت نشد. لطفا بعدا تلاش کنید.',
    removeFilters:
        'پروفایلی مطابق با فیلترهای اعمال شده یافت نشد. برای جستجوی گسترده تر فیلترهای اعمال شده خود را کاهش دهید.',
    compatibility: {
        [__MBTIMatchEnum.perfect]: 'سازگاری ایده آل',
        [__MBTIMatchEnum.high]: 'سازگاری بالا',
        [__MBTIMatchEnum.moderate]: 'سازگاری متوسط',
        [__MBTIMatchEnum.low]: 'سازگاری پایین',
    },
    mbti: {
        [MBTIEnum.ENFJ]: {
            index: 'الهام‌بخش',
            desc: 'رهبران کاریزماتیک و الهام‌بخش، قادر هستند شنوندگان خود را هیپنوتیزم کنند.',
        },
        [MBTIEnum.ENFP]: {
            index: 'قهرمان',
            desc: 'روح‌های آزاد مشتاق، خلاق و خوش‌مشرب که همیشه می‌توانند دلیلی برای لبخند پیدا کنند',
        },
        [MBTIEnum.ENTJ]: {
            index: 'رهبر',
            desc: 'رهبران جسور، دارای تخیل و اراده قوی که همیشه راه حلی را پیدا می‌کنند؛ یا حتی می‌سازند.',
        },
        [MBTIEnum.ENTP]: {
            index: 'آینده‌نگر',
            desc: 'متفکران هوشمند و کنجکاو که نمی‌توانند در برابر وسوسه چالش فکری مقاومت کنند.',
        },
        [MBTIEnum.ESFJ]: {
            index: 'حامی',
            desc: 'افرادی فوق‌العاده مراعاتگر، اجتماعی و محبوب، همیشه مشتاق کمک کردن هستند.',
        },
        [MBTIEnum.ESFP]: {
            index: 'سرگرم‌کننده',
            desc: 'افراد خودانگیخته، پرانرژی و مشتاق، زندگی کردن در اطراف آنها هرگز کسل‌کننده نیست.',
        },
        [MBTIEnum.ESTJ]: { index: 'سازمان‌دهنده', desc: 'مدیران عالی، بی‌نظیر در مدیریت امور، یا مردم.' },
        [MBTIEnum.ESTP]: {
            index: 'کارآفرین',
            desc: 'افرادی هوشمند، پرانرژی و بسیار اهل درک که واقعاً از زندگی کردن حاشیه لذت می‌برند.',
        },
        [MBTIEnum.INFJ]: {
            index: 'مشاور',
            desc: 'کمال‌گرایانی ساکت و پر رمز و راز، در عین حال بسیار الهام‌بخش و خستگی‌ناپذیر.',
        },
        [MBTIEnum.INFP]: {
            index: 'دلسوز',
            desc: 'افراد شاعرمسلک، مهربان و نوع‌دوست، همیشه مشتاق کمک به هدفی خوب هستند.',
        },
        [MBTIEnum.INTJ]: { index: 'استراتژیست', desc: 'متفکران دارای تخیل و استراتژی که برای همه چیز برنامه دارند.' },
        [MBTIEnum.INTP]: { index: 'نظریه‌پرداز', desc: 'مخترعان نوآور که عطش سیری‌ناپذیر برای دانش دارند.' },
        [MBTIEnum.ISFJ]: { index: 'محافظ', desc: 'محافظانی بسیار متعهد و گرم، همیشه آماده دفاع از عزیزان خود هستند.' },
        [MBTIEnum.ISFP]: {
            index: 'هنردوست',
            desc: 'هنرمندانی انعطاف‌پذیر و جذاب، همیشه آماده کشف و تجربه چیزهای جدید هستند.',
        },
        [MBTIEnum.ISTJ]: {
            index: 'تحلیل‌گر',
            desc: 'افراد عمل‌گرا و واقع‌اندیش، کسانی که در قابل اعتماد بودنشان جای شکی نیست.',
        },
        [MBTIEnum.ISTP]: { index: 'سازنده', desc: 'آزمایش‌کنندگانی جسور و عمل‌گرا، استادان همه نوع ابزار.' },
    },
    personalInfo: {
        [PersonalInfoEnum.bodyShape]: 'اندام',
        [PersonalInfoEnum.calmness]: 'آرامش',
        [PersonalInfoEnum.cooking]: 'پخت و پز',
        [PersonalInfoEnum.entertainment]: 'سرگرمی',
        [PersonalInfoEnum.height]: 'قد',
        [PersonalInfoEnum.job]: 'شغل',
        [PersonalInfoEnum.jobType]: 'نوع شغل',
        [PersonalInfoEnum.sleep]: 'خواب',
        [PersonalInfoEnum.stay]: 'اقامت',
        [PersonalInfoEnum.travel]: 'سفر',
    },
    moreInfo: {
        [PersonalInfoEnum.height]: {
            [HeightEnum.lessThan150]: 'قد کمتر 150 سانتی متر',
            [HeightEnum.from150to160]: 'قد 150 الی 160 سانتی متر',
            [HeightEnum.from160to170]: 'قد 160 الی 170 سانتی متر',
            [HeightEnum.from170to180]: 'قد 170 الی 180 سانتی متر',
            [HeightEnum.from180to190]: 'قد 180 الی 190 سانتی متر',
            [HeightEnum.moreThan190]: 'قد بیشتر 190 سانتی متر',
        },
        [PersonalInfoEnum.sleep]: {
            [SleepEnum.stayAwakeAtNight]: 'شب زنده داری',
            [SleepEnum.earlyBird]: 'سحر خیزی',
        },
        [PersonalInfoEnum.job]: {
            [JobEnum.employer]: 'کارمند',
            [JobEnum.doctor]: 'پزشک',
            [JobEnum.teacher]: 'معلم',
            [JobEnum.freelancer]: 'فری لنسر',
            [JobEnum.personalBusiness]: 'کسب و کار شخصی',
            [JobEnum.consultant]: 'مشاور',
            [JobEnum.entrepreneur]: 'کارآفرین',
            [JobEnum.housekeeper]: 'خانه دار',
        },
        [PersonalInfoEnum.cooking]: {
            [CookingEnum.inHome]: 'پخت و پز در خانه',
            [CookingEnum.orderIn]: 'سفارش غذا از بیرون',
        },
        [PersonalInfoEnum.jobType]: {
            [JobTypeEnum.attendanceWork]: 'کار حضوری',
            [JobTypeEnum.absenteeWork]: 'کار غیرحضوری',
        },
        [PersonalInfoEnum.stay]: {
            [StayEnum.ecotourist]: 'کمپینگ در طبیعت',
            [StayEnum.hotel]: 'اقامت در هتل',
        },
        [PersonalInfoEnum.calmness]: {
            [CalmnessEnum.peaceAndQuiet]: 'آرامش و سکوت',
            [CalmnessEnum.partyAndDance]: 'رقص و مهمانی',
        },
        [PersonalInfoEnum.bodyShape]: {
            [BodyShapeEnum.slenderBody]: 'باریک اندام',
            [BodyShapeEnum.fit]: 'متناسب (فیت)',
            [BodyShapeEnum.muscular]: 'عضلانی',
            [BodyShapeEnum.aLittleOverweight]: 'کمی اضافه وزن',
            [BodyShapeEnum.overweight]: 'اضافه وزن',
        },
        [PersonalInfoEnum.travel]: {
            [TravelEnum.toUrban]: 'مسافرت به مناطق شهری',
            [TravelEnum.toNature]: 'مسافرت به مناطق طبیعی',
        },
        [PersonalInfoEnum.entertainment]: {
            [EntertainmentEnum.book]: 'کتابخوانی',
            [EntertainmentEnum.videoGame]: 'بازی ویدیوئی',
        },
    },
};
