import { AppContextActionKeyEnum, AppContextActionType, AppContextType } from 'types';

// helper function for container.tsx
// check type and set given value to exact field of state in order to given type
export function globalStateSetter(e: AppContextActionType[], state: AppContextType): AppContextType {
    const newState: AppContextType = { ...state };
    for (const action of e) {
        switch (action.key) {
            case AppContextActionKeyEnum.langText:
                newState.langText = action.value;
                break;
            case AppContextActionKeyEnum.profile:
                newState.profile = action.value;
                break;
            case AppContextActionKeyEnum.blogPostData:
                newState.blogPost = action.value;
                break;
        }
    }
    return newState;
}
