export const __HousesDetailLang = {
    share: 'اشتراک گذاری',
    back: 'برگشت',
    unit_specifications: 'مشخصات واحد',
    buildingType: 'نوع ساختمان:',
    shared: 'اشتراکی',
    independent: 'مستقل',
    month: 'ماه',
    facilities: 'امکانات:',
    limitations: 'محدودیت ها:',
    other_conditions: 'سایر شرایط:',
    timing: 'زمانبندی',
    startDate: 'تاریخ شروع سکونت:',
    endDate: 'تاریخ پایان سکونت:',
    area: 'منطقه',
    place: 'محله:',
    see: 'مشاهده حدود منطقه در google map',
    year: 'ساله',
    resident_gender: 'جنسیت ساکن:',
    roommate_gender: 'جنسیت موردنظر برای همخانه:',
    tel: 'ارتباط در تلگرام',
    copy_alert: 'آدرس خانه مورد نظر در کلیپ بورد دستگاه ذخیره شد',
};
