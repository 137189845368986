export const __HousesListLang = {
    filters: 'فیلتر ها',
    buildingType: 'نوع ساختمان',
    title: 'جست و جو خانه',
    newest: 'جدیدترین',
    most_visited: 'پربازدید ترین',
    ordering: 'مرتب سازی',
    oldest: 'قدیمی ترین',
    most_popular: 'محبوب ترین',
    result: 'نتیجه',
    shared: 'اشتراکی',
    independent: 'مستقل',
    month: 'ماه',
    search: 'جست و جو هم خونه',
    searchDesc: 'همخانه برای اشتراک خانه خود',
    search2: 'جست و جو مستاجر',
    searchDesc2: 'مستاجر برای اجاره دادن خانه خود',
};
