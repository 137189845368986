export const __AskForHomeFormLang = {
    personalInfoSectionTitle: 'اطلاعات شخصی',
    telegramIdLabel: 'آیدی اکانت تلگرام شما',
    telegramIdEmptyError: 'لطفا آیدی اکانت تلگرام خود را وارد کنید.',
    bioLabel: 'بیو',
    bioPlaceholder: 'کمی درباره خودتان بنویسید',
    timingSectionTitle: 'زمان بندی',
    residencePeriodLabel: 'مدت سکونت',
    residencePeriodEmptyError: 'لطفا مدت سکونت خود را وارد کنید.',
    residencePeriodPlaceholder: 'انتخاب کنید',
    startDateLabel: 'بازه تاریخ سکونت',
    endDateEmptyError: 'لطفا بازه تاریخ سکونت خود را وارد کنید.',
    endDatePlaceholder: 'انتخاب تاریخ',
    buildingTypeAndFacilitiesSectionTitle: 'مشخصات واحد مورد نظر',
    buildingTypeLabel: 'نوع ساختمان',
    buildingTypeEmptyError: 'لطفا نوع ساختمان خود را وارد کنید.',
    buildingTypePlaceholder: 'انتخاب کنید',
    residenceTypeLabel: 'نوع اقامت',
    residenceTypeEmptyError: 'لطفا نوع اقامت خود را وارد کنید.',
    residenceTypePlaceholder: 'انتخاب کنید',
    neighborhoodsLabel: 'محله ها',
    neighborhoodsEmptyError: 'لطفا محله‌های مورد نظر خود را وارد کنید.',
    neighborhoodsPlaceholder: 'انتخاب کنید',
    requiredFacilitiesLabel: 'امکانات مورد نیاز',
    requiredFacilitiesEmptyError: 'لطفا امکانات مورد نیاز خود را وارد کنید.',
    requiredFacilitiesPlaceholder: 'انتخاب کنید',
    desiredRestrictionsLabel: 'محدودیت های مورد نظر',
    desiredRestrictionsEmptyError: 'لطفا محدودیت‌های مورد نظر خود را وارد کنید.',
    desiredRestrictionsPlaceholder: 'انتخاب کنید',
    otherTermsLabel: 'سایر شرایط مورد نظر شما',
    otherTermsPlaceholder: 'شرایط دیگری که مد نظر شماست را بنویسید',
    budgetLabel: 'بودجه',
    budgetEmptyError: 'لطفا بودجه خود را وارد کنید.',
    budgetPlaceholder: 'بودجه شما چقدر است (CAD)',
    submitFormBtn: 'تایید و جستجوی خانه',
    normal_room: 'اتاق معمولی با سرویس و حمام مشترک',
    master_room: 'اتاق مستر با سرویس و حمام مستقل',
    small_room: 'دن یا اتاق کوچک با سرویس و حمام مشترک',
    canape: 'کاناپه یا تخت در محیط مشترک',
    independent_common: 'واحد مستقل با ورودی مشترک',
    independent_independent: 'واحد مستقل با ورودی مستقل',
};
