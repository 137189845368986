import { RateCommentEnum, RateEnum } from 'types';
import { __OrderDetailLang } from './orderDetail.lang';

export const __StarBoxItems = (lang: typeof __OrderDetailLang) => [
    {
        id: RateEnum.grate,
        starCount: ['1', '2', '3', '4', '5'],
        title: lang.great,
        className: 'great',
    },
    {
        id: RateEnum.good,
        starCount: ['1', '2', '3', '4'],
        title: lang.good,
        className: 'good',
    },
    {
        id: RateEnum.medium,
        starCount: ['1', '2', '3'],
        title: lang.medium,
        className: 'medium',
    },
    {
        id: RateEnum.weak,
        starCount: ['1', '2'],
        title: lang.weak,
        className: 'weak',
    },
    {
        id: RateEnum.veryBad,
        starCount: ['1'],
        title: lang.very_bad,
        className: 'very-bad',
    },
];

export const __StarCommentGoodItems = (lang: typeof __OrderDetailLang) => [
    {
        id: RateCommentEnum.goodPrice,
        title: lang.goodPrice,
    },
    {
        id: RateCommentEnum.goodSpace,
        title: lang.goodSpace,
    },
    {
        id: RateCommentEnum.goodResponse,
        title: lang.goodResponse,
    },
    {
        id: RateCommentEnum.goodSupport,
        title: lang.goodSupport,
    },
    {
        id: RateCommentEnum.goodQuality,
        title: lang.goodQuality,
    },
    {
        id: RateCommentEnum.goodInformation,
        title: lang.goodInformation,
    },
];

export const __StarCommentBadItems = (lang: typeof __OrderDetailLang) => [
    {
        id: RateCommentEnum.badPrice,
        title: lang.badPrice,
    },
    {
        id: RateCommentEnum.badSpace,
        title: lang.badSpace,
    },
    {
        id: RateCommentEnum.badResponse,
        title: lang.badResponse,
    },
    {
        id: RateCommentEnum.badSupport,
        title: lang.badSupport,
    },
    {
        id: RateCommentEnum.badQuality,
        title: lang.badQuality,
    },
    {
        id: RateCommentEnum.badInformation,
        title: lang.badInformation,
    },
];
