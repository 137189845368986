import { TehrantoLangEN } from 'i18n/EN';
import { TehrantoLangFA } from 'i18n/FA';
import { AppContextType, BlogPostType, LanguageEnum, ProfileType } from 'types';

// initial value of the global state in
export const globalContextInitialValue: (
    lang: LanguageEnum,
    profile?: ProfileType,
    blogPost?: BlogPostType,
) => AppContextType = (lang, profile, blogPost) => ({
    update: (...e) => {
        return 'this one is going to change in container.tsx' + e.length;
    },
    langText: lang === LanguageEnum.en ? TehrantoLangEN : TehrantoLangFA,
    profile,
    blogPost,
});
