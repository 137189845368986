export const __EventDetailLang = {
    category: 'دسته بندی:',
    description: 'توضیحات',
    event_conditions: 'شرایط دورهمی',
    event_details: 'جزئیات دورهمی',
    share: 'اشتراک گذاری',
    host: 'میزبان:',
    time_holding: 'زمان برگزاری:',
    venue: 'مکان برگزاری:',
    capacity: 'ظرفیت',
    request: 'درخواست شرکت در دورهمی',
    other_periods: 'سایر دورهمی ها',
    people: 'نفر',
    out_of: 'از',
    copy_alert: 'آدرس ایونت موردنظرتان در کلیپ بوردتان ذخیره شد',
};
