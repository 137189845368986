import { DatingProfileType, MBTIEnum, PersonalInfoType } from 'types';

export type __MBTIPersonalListType = {
    mbti: MBTIEnum;
    matchType: __MBTIMatchEnum;
    count?: number;
    data?: DatingProfileType[];
};

export type __ActiveProfileType = {
    data: DatingProfileType;
    mbti?: MBTIEnum;
    index: number;
};

export enum __MBTIMatchEnum {
    perfect,
    high,
    moderate,
    low,
}

export type __DatingListPropsType = {
    profileList: __MBTIPersonalListType[];
    initialFilters: __DatingFilterType;
};

export type __DatingFilterType = Partial<Record<keyof PersonalInfoType, number[]>>;
export type __DatingAPIFilterType = { [K in keyof __DatingFilterType as `${K}__in`]: string };
