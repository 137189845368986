'use client';
import styles from './bottomNavigation.module.scss';
import { usePathname } from 'next/navigation';
import { PATH } from 'data';
import { useContext, useState } from 'react';
import { GlobalContext } from 'context';
import { AppLinkComp } from '..';
import {
    BiHome,
    BiSolidHome,
    BiUser,
    BiSolidUser,
    BiCategory,
    BiSolidCategory,
    BiSearch,
    BiSolidSearch,
} from 'react-icons/bi';
import { Link, useInRouterContext } from 'react-router-dom';
import { ServiceNavigationComp } from '@/components';
import { RiHeartsFill, RiDiscountPercentFill } from 'react-icons/ri';
import { GoHomeFill } from 'react-icons/go';
import { HiUserGroup } from 'react-icons/hi';
import { ServiceTypeEnum } from '@/types';

function BottomNavigationComp({
    className,
    activeIndex,
    setActiveIndex,
}: {
    className?: string;
    activeIndex?: ServiceTypeEnum;
    setActiveIndex?: (e: ServiceTypeEnum) => void;
}) {
    const [serviceActive, setServiceActive] = useState(false);
    const pathname = usePathname();
    const { langText } = useContext(GlobalContext);
    const lang = langText.bottomNavigation;
    const routerContext = useInRouterContext();

    const AdvanceLink: React.FC<React.PropsWithChildren<{ href: string; className: string }>> = (props) =>
        routerContext ? (
            <Link to={props.href} className={props.className}>
                {props.children}
            </Link>
        ) : (
            <AppLinkComp href={props.href} className={props.className}>
                {props.children}
            </AppLinkComp>
        );

    const serviceBtns = () => {
        switch (activeIndex) {
            case ServiceTypeEnum.offer:
                return <RiDiscountPercentFill size={30} />;
            case ServiceTypeEnum.dating:
                return <RiHeartsFill size={30} />;
            case ServiceTypeEnum.housing:
                return <GoHomeFill size={30} />;
            case ServiceTypeEnum.events:
                return <HiUserGroup size={30} />;
            default:
                return <RiDiscountPercentFill size={30} />;
        }
    };

    return (
        <div className={`${styles['bottom-navigation']} w-100 d-md-none ${className}`}>
            <div className={`${styles['container']} ph-1 h-100 center-content-y d-flex justify-content-between`}>
                <ServiceNavigationComp
                    setServiceActive={setServiceActive}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    className={`${serviceActive ? styles['show'] : ''} ${styles['fixed']}`}
                />
                <>
                    {PATH(langText.lang).home() === pathname.split('?')[0] ? (
                        <div className={`gap-8 center-content flex-column p-1 ${styles['active']}`}>
                            <BiSolidHome size={22} className={`t-primary`} />{' '}
                            <span className="t-primary">{lang.home}</span>
                        </div>
                    ) : (
                        <AppLinkComp className="center-content flex-column" href={PATH(langText.lang).home()}>
                            <BiHome className="t-dark2 ph-1" size={36} />
                            <p className="t-p3 t-400">{lang.home}</p>
                        </AppLinkComp>
                    )}
                    {PATH(langText.lang).categories === pathname ? (
                        <div className={`gap-8 center-content flex-column p-1 ${styles['active']}`}>
                            <BiSolidCategory size={22} className={`t-primary`} />{' '}
                            <span className="t-primary">{lang.categories}</span>
                        </div>
                    ) : (
                        <AppLinkComp className="center-content flex-column" href={PATH(langText.lang).categories}>
                            <BiCategory className="t-dark2 ph-1" size={36} />
                            <p className="t-p3 t-400">{lang.categories}</p>
                        </AppLinkComp>
                    )}
                    <span
                        onClick={() => setServiceActive(true)}
                        className={`${styles['services-btn']} center-content pointer`}
                    >
                        {serviceBtns()}
                    </span>
                    {PATH(langText.lang).search === pathname ? (
                        <div className={`gap-8 center-content flex-column p-1 ${styles['active']}`}>
                            <BiSolidSearch size={22} className={`t-primary`} />{' '}
                            <span className="t-primary">{lang.search}</span>
                        </div>
                    ) : (
                        <AppLinkComp className="center-content flex-column" href={PATH(langText.lang).search}>
                            <BiSearch className="t-dark2 ph-1" size={36} />
                            <p className="t-p3 t-400">{lang.search}</p>
                        </AppLinkComp>
                    )}
                    {pathname.includes(PATH(langText.lang).panel.index) ? (
                        <AdvanceLink
                            href={PATH(langText.lang).panel.index}
                            className={`gap-8 center-content flex-column p-1 ${styles['active']}`}
                        >
                            <BiSolidUser size={22} className={`t-primary`} />{' '}
                            <span className="t-primary">{lang.user}</span>
                        </AdvanceLink>
                    ) : (
                        <AdvanceLink className="center-content flex-column" href={PATH(langText.lang).panel.index}>
                            <BiUser className="t-dark2 ph-1" size={36} />
                            <p className="t-p3 t-400">{lang.user}</p>
                        </AdvanceLink>
                    )}
                </>
            </div>
        </div>
    );
}

export default BottomNavigationComp;
