'use client';
import styles from './tehrantoFeatures.module.scss';
import Image from 'next/image';
import WalletSVG from '@/assets/images/WalletMoney.svg';
import HeartSVG from '@/assets/images/Heart.svg';
import SubtractSVG from '@/assets/images/Subtract.svg';
import SuitcaseSVG from '@/assets/images/Suitcase.svg';
import bg from 'assets/images/featuresSVG.svg';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import CountUp from 'react-countup';

function TehrantoFeaturesComp({ title, className }: { title?: string; className?: string }) {
    const lang = useContext(GlobalContext).langText.components;
    const cardData = [
        {
            id: 1,
            svg: WalletSVG,
            title: lang.refund,
            prefix: '+',
            suffix: '$',
            count: '100000',
        },
        {
            id: 2,
            svg: HeartSVG,
            title: lang.customerSatisfaction,
            prefix: '+',
            count: '1000',
        },
        {
            id: 3,
            svg: SuitcaseSVG,
            title: lang.partnerCompanies,
            prefix: '+',
            count: '100',
        },
        {
            id: 4,
            svg: SubtractSVG,
            title: lang.users,
            prefix: '+',
            count: '10000',
        },
    ];

    return (
        <div className={`${styles['bg-content']} ${className ?? ''}`}>
            <div className="main-content">
                <h3 className="t-h2 t-800 text-center">{title ?? ''}</h3>
                <div className={`${styles['card-content']} d-flex gap-24 mt-6`}>
                    {cardData.map((data) => (
                        <div key={data.id} className={`${styles['card']} flex-column center-content gap-16`}>
                            <p className="t-700 t-h4 t-white">{data.title}</p>
                            <div className={`${styles['card-svg']} d-flex flex-column align-items-center`}>
                                <Image src={data.svg} alt="bg-SVG" width={100} height={100} />
                                <p className="t-900 text-center t-white ltr">
                                    {data.prefix}
                                    <CountUp
                                        end={Number(data.count.split('%').join('').split('+').join(''))}
                                        duration={3}
                                    />
                                    {data.suffix}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles['background']}>
                <Image width={1618} height={702} alt="background" src={bg} />
            </div>
        </div>
    );
}

export default TehrantoFeaturesComp;
