'use client';
import { PropsWithChildren, useEffect, useState } from 'react';

const ClientRenderComp: React.FC<PropsWithChildren> = (props) => {
    const [render, setRender] = useState<boolean>(false);

    useEffect(() => {
        setRender(true);
    }, []);

    if (render) return props.children;
    else return null;
};
export default ClientRenderComp;
