export const __OrderDetailLang = {
    upload_pending: 'در انتظار بارگذاری خرید',
    pending: 'درحال بررسی',
    success: 'کش بک موفق',
    rejected: 'رد شده',
    back: 'بازگشت',
    purchase_review: 'بررسی خرید',
    upload_purchase: 'بارگذاری خرید',
    purchase_date: 'تاریخ خرید',
    order_status: 'وضعیت سفارش:',
    purchase_detail: 'جزئیات خرید',
    unconfirmed_purchase: 'عدم تایید خرید',
    great: 'عالی',
    good: 'خوب',
    medium: 'متوسط',
    weak: 'ضعیف',
    very_bad: 'خیلی بد',
    preOrderSubmit: 'ثبت پیش سفارش',
    preOrderDesc: 'پیش سفارش شما با موفقیت ثبت شد',
    purchase_review_desc: 'خرید شما درحال بررسی میباشد و پس از بررسی نتیجه آن برای شما قابل مشاهده میباشد.',
    success_desc: 'بررسی خرید شما با موفقیت انجام شد. همکاران ما جهت بازگشت وجه با شما تماس خواهند گرفت.',
    tracking_code: 'کد پیگیری',
    unconfirmed_purchase_desc:
        'مدارک خرید شما مورد تایید قرار نگرفت. لطفا مدارک خرید خود را به صورت کامل مجددا بارگذاری نمایید.',
    request: 'درخواست بررسی مجدد',
    upload_purchase_desc: (brandName?: string, offer_price?: string, extra_service?: string) =>
        `با ثبت مدارک و تجربه خرید خود از ${brandName} در این بخش، فارغ از هر تخفیف یا آفری که توسط این مجموعه ارائه می شود،شما میتوانید ${offer_price} از تهرانتو کلاب دریافت نمایید. هماهنگی ${extra_service} می بایست در حین خرید شما از ${brandName} به صورت مستقیم انجام شود. تهرانتو کلاب همواره به عنوان پشتیبان، در این فرایند خرید همراه شما خواهد بود اما در نهایت این فیدبک و تجربه شما می باشد که باعث تضمین کیفیت و ارتقاء سطح خدمات می شود.`,
    experience: 'تجربه خرید خود را بنویسید',
    comment:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.',
    registered: 'ثبت شده',
    registered_save: 'ثبت و ذخیره',
    write_comment: 'نظر خود را بنویسید ...',
    goodPrice: 'قیمت مناسب',
    goodSpace: 'فضای مناسب',
    goodResponse: 'پاسخگویی مناسب',
    goodSupport: 'پشتیبانی مناسب',
    goodQuality: 'کیفیت مناسب',
    goodInformation: 'اطلاعات مناسب',
    badPrice: 'قیمت',
    badSpace: 'محیط',
    badResponse: 'پاسخگویی',
    badSupport: 'پشتیبانی',
    badQuality: 'کیفیت',
    badInformation: 'اطلاعات',
    select_purchase_features: 'ویژگی های خرید را انتخاب کنید:',
    review: 'ثبت نظر و امتیاز',
    review_pending: 'در انتظار نظردهی',
    error: 'خطایی در بارگذاری فایل رخ داده است. لطفا مجددا تلاش نمایید',
    errorOrder: 'خطایی در ارسال اطلاعات رخ داده است. لطفا مجددا تلاش نمایید',
    successOrder: 'ثبت اطلاعات با موفقیت انجام شد.',
    dropFile: 'فایل تصویر یا pdf را انتخاب و یا در اینجا رها کنید',
    viewFile: 'برای مشاهده رسیدهای بارگذاری شده بر روی موراد زیر کلیک کنید',
    downloadPDF: 'چاپ',
};
