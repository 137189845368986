export const headerLang = {
    tehranto: 'تـهرانتـو',
    otherLang: 'En',
    home: 'خانه',
    services: 'خرید و خدمات',
    cacheBack: 'آشنایی و دوستی',
    jobSearch: 'دورهمی و ایونت',
    search: 'اقامت و همخانه',
    aboutUs: 'درباره ما',
    others: 'سایر',
    blog: 'وبلاگ',
    signup: 'ثبت نام',
    login: 'ورود',
    profile: 'حساب کاربری',
};
