export const __FindingFriendLang = {
    findingFriend: 'دوستیابی',
    personalInformation: 'اطلاعات شخصی',
    name: 'نام',
    placeholderName: 'نام خود را بنویسید',
    yearBirth: 'سال تولد',
    placeholderYear: 'سال تولد میلادی خود را وارد کنید',
    placeholderSelected: 'انتخاب کنید',
    gender: 'جنسیت',
    desiredGender: 'جنسیت مورد نظر',
    telegramID: 'آیدی تلگرام',
    placeholderTel: 'آیدی تلگرام خود را بنویسید',
    city: 'شهر',
    yourPictures: 'تصاویر شما',
    furtherInformation: 'اطلاعات تکمیلی',
    submit: 'تایید و ادامه',
    name_error: 'لطفا نام خود را وارد کنید!',
    year_error: 'لطفا سال تولد خود را وارد کنید!',
    gender_error: 'لطفا جنسیت خود را وارد کنید!',
    d_gender_error: 'لطفا جنسیت را وارد کنید!',
    telegram_error: 'لطفا آیدی تلگرام خود را وارد کنید!',
    city_error: 'لطفا شهر خود را وارد کنید!',
    gallery_error: 'لطفا تصاویر گالری را وارد کنید.',
    invalidID: 'فرمت آیدی وارد شده صحیح نیست!',
    female: 'زن',
    male: 'مرد',
    error: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    success: 'اطلاعات شما با موفقیت ثبت شد.',
    update: 'اطلاعات شما با موفقیت بروزرسانی شد.',
    startType: 'شروع به تایپ کنید',
    startTypeDesc: 'جهت یافتن شهر مورد نظرتان نام شهر را تایپ کنید',
    createProfileInfo: 'جهت دسترسی به پروفایل‌ اشخاص دیگر لازم است ابتدا اطلاعات خود را ثبت کنید.',
    activeProfile: 'جهت مشاهده پروفایل اشخاص دیگر، باید پروفایل خود را فعال کنید.',
    paymentSuccess: 'خرید شما با موفقیت انجام شد.',
    paymentFail: 'خرید شما با خطا مواجه شد',
};
