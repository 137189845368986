export const __LoginOTPLang = {
    login_title: 'ورود به حساب کاربری',
    login_desc: 'برای ورود به شماره تماس خود را وارد کنید.',
    phone: 'شماره تماس',
    code: 'کد تایید',
    codeDesc1: 'کد تایید برای شماره ',
    codeDesc2: 'ارسال شد.',
    sendPhone: 'ارسال کد تایید',
    login_account: 'ورود به حساب',
    error: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    phoneIncorrect: 'لطفا یک شماره تماس صحیح وارد نمایید',
    phoneRequired: 'لطفا شماره تماس خود را وارد کنید',
    codeRequired: 'لطفا کد تایید ارسالی را وارد نمایید',
    codeSubmitError: 'اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش نمایید',
    resend_code_first: 'کدی دریافت نشد؟',
    resend_code_second: 'ارسال کد فعالسازی',
    resend_code_third: 'در',
    resend_code_end: 'ثانیه دیگر',
    change_phone: 'تغییر شماره تماس',
    search: 'جستجو...',
    terms: 'ورود شما به معنای پذیرش -- شرایط و قوانین تهرانتو -- است',
};
