export const __HomeAdsLang = {
    shared: 'اشتراکی',
    independent: 'مستقل',
    buildingType: 'نوع ساختمان',
    month: 'ماه',
    title: 'آگهی های خانه',
    newAds: 'افزودن آگهی جدید',
    enable: 'فعال',
    disable: 'غیر فعال',
    empty: 'آگهی وجود ندارد',
    independent_house: 'خانه مستقل',
    shared_house: 'خانه اشتراکی',
};
