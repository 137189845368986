export const __CreateEventLang = {
    title: 'برگزاری ایونت',
    return: 'بازگشت',
    contentTitle: 'اطلاعات ایونت',
    title_label: 'عنوان ایونت',
    title_placeholder: 'عنوان ایونت را بنویسید',
    title_error: 'لطفا نام ایونت را وارد کنید.',
    category_label: 'دسته بندی',
    category_placeholder: 'انتخاب کنید',
    category_error: 'لطفا دسته بندی را وارد کنید.',
    capacity_label: 'ظرفیت ایونت',
    capacity_placeholder: 'ظرفیت ایونت چند نفر است',
    capacity_error: 'لطفا ظرفیت ایونت را وارد کنید.',
    cost_label: 'هزینه',
    cost_placeholder: 'هزینه ایونت',
    cost_error: 'لطفا هزینه را وارد کنید.',
    cost_patternError: 'لطفا عدد وارد کنید.',
    date_label: 'تاریخ برگزاری',
    date_placeholder: 'انتخاب تاریخ',
    date_error: 'لطفا تاریخ برگزاری را وارد کنید.',
    clock_label: 'ساعت برگزاری',
    clock_placeholder: 'ساعت برگزاری جلسه',
    clock_error: 'لطفا ساعت جلسه را وارد کنید.',
    desc_label: 'توضیحات',
    desc_placeholder: 'توضیحات درباره ایونت را بنویسید',
    desc_error: 'لطفا توضیحات ایونت را وارد کنید.',
    terms_label: 'شرایط استفاده',
    terms_placeholder: 'شرایط شرکت در ایونت را بنویسید',
    terms_error: 'لطفا شرایط شرکت در ایونت را وارد کنید.',
    address_label: 'مکان برگزاری',
    address_placeholder: 'آدرس برگزاری ایونت',
    address_error: 'لطفا آدرس دقیق را وارد کنید.',
    geo_error: 'لطفا موقعیت جغرافیایی را انتخاب کنید.',
    gallery_label: 'گالری تصاویر ایونت',
    gallery_error: 'لطفا تصاویر گالری را وارد کنید.',
    address_p: 'آدرس محل برگزاری ایونت را روی نقشه تعیین و آدرس کلی آن را به صورت نوشتاری در بخش بالا بنویسید.',
    address_note: 'توجه: آدرس دقیق شما نمایش داده نخواهد شد.',
    submit: 'تایید و ایجاد ایونت',
};
