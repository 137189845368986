export const __RoommatesDetailLang = {
    share: 'اشتراک گذاری',
    back: 'برگشت',
    unit_specifications: 'مشخصات واحد',
    buildingType: 'نوع ساختمان:',
    shareMale: 'اشتراک با آقا',
    shareFemale: 'اشتراک با خانم',
    independent: 'مستقل',
    month: 'ماه',
    facilities: 'امکانات:',
    limitations: 'محدودیت ها:',
    other_conditions: 'سایر شرایط:',
    timing: 'زمانبندی',
    startDate: 'تاریخ شروع سکونت:',
    endDate: 'تاریخ پایان سکونت:',
    year: 'ساله',
    resident_gender: 'جنسیت ساکن:',
    roommate_gender: 'جنسیت موردنظر برای همخانه:',
    tel: 'ارتباط در تلگرام',
    copy_alert: 'آدرس همخانه مورد نظر در کلیپ بورد دستگاه ذخیره شد',
};
