export const __BrandDetailLang = {
    filters: 'فیلتر ها',
    company_services: 'خدمات شرکت',
    newest: 'جدیدترین',
    oldest: 'قدیمی ترین',
    mostPriority: 'منتخب',
    most_visited: 'پربازدید ترین',
    most_popular: 'محبوب ترین',
    maximum_cashback: 'بیشترین بازگشت وجه',
    ordering: 'مرتب سازی',
    result: 'نتیجه',
    customers_comments: 'نظرات مشتریان',
    comments_service: 'نظر درباره خدمت',
    errorMessage: 'درخواست با خطا مواجه شد.',
    empty: 'خدمتی با فیلتر مورد نظر یافت نشد.',
    initializeError: 'انتخاب با خطا مواجه شد.',
    initializeSuccess: 'پیش سفارش شما با موفقیت ثبت شد',
};
