export const __InitRoomAndRoomMateLang = {
    title: 'خانه و همخانه',
    rent_home_title: 'به دنبال خانه میگردید؟',
    rent_home_text:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
    renting_an_independent_unit: 'اجاره واحد مستقل',
    shared_unit_rental: 'اجاره واحد اشتراکی',
    room_owner_title: 'خانه دارید؟',
    room_owner_text:
        'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است.',
    rent_independently: 'اجاره دادن به صورت مستقل',
    shared_rental: 'اجاره دادن به صورت اشتراکی',
    return_back: 'بازگشت',
};
