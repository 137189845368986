export const __LoginLang = {
    login_title: 'ورود به حساب کاربری',
    login_desc: 'برای ورود به حساب آدرس ایمیل و رمز عبور خود را وارد کنید.',
    email_title: 'آدرس ایمیل',
    password_title: 'رمز عبور',
    forget_pass: 'رمز عبور خود را فراموش کردید؟',
    recovery_link: 'بازیابی رمز عبور',
    login_account: 'ورود به حساب',
    create_account: 'ایجاد حساب کاربری',
    emailIncorrect: 'لطفا یک ایمیل صحیح وارد نمایید',
    emailRequired: 'لطفا ایمیل خود را وارد کنید',
    passwordRequired: 'لطفا رمز عبور خود را وارد نمایید',
    codeSubmitError: 'اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش نمایید',
};
