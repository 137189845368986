export const __HomeRequestLang = {
    title1: 'درخواست خانه اشتراکی',
    title2: 'درخواست خانه مستقل',
    disabled: 'غیر فعال سازی',
    activation: 'فعال سازی',
    editInfo: 'ویرایش اطلاعات',
    lookingFor: 'همچنان به دنبال خانه و همخانه میگردم',
    info: 'اطلاعات شخصی',
    telID: 'آیدی اکانت تلگرام شما:',
    yearBirth: 'سال تولد:',
    gender: 'جنسیت:',
    dGender: 'جنسیت مورد نظر برای همخونه:',
    bio: 'بیو:',
    timing: 'زمان بندی',
    startDate: 'تاریخ شروع سکونت',
    endDate: 'تاریخ پایان سکونت',
    roomInfo: 'مشخصات واحد مورد نظر ',
    buildingType: 'نوع ساختمان:',
    stayType: 'نوع اقامت:',
    place: 'محله ها:',
    budget: 'بودجه:',
    possibilities: 'امکانات:',
    limitations: 'محدودیت ها:',
    other: 'سایر شرایط مورد نظر شما:',
    female: 'زن',
    male: 'مرد',
    empty: 'درخواستی وجود ندارد',
};
