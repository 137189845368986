export const __OrderLang = {
    my_purchases: 'خرید های من',
    service_title: 'عنوان خدمات',
    date: 'تاریخ',
    status: 'وضعیت',
    purchase_details: 'جزئیات خرید',
    view_details: 'مشاهده جزئیات',
    view: 'مشاهده',
    upload_pending: 'در انتظار بارگذاری خرید',
    review_pending: 'در انتظار نظردهی',
    pending: 'درحال بررسی',
    success: 'کش بک موفق',
    rejected: 'رد شده',
    back: 'بازگشت',
};
