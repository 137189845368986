export const __AboutUsLang = {
    slogan: 'تهرانتو کلاب',
    title: 'درباره و چشم انداز ما',
    desc: 'تهرانتو کلاب | Tehranto Club پلتفرمی از فرصت ها برای حال خوب بعد از مهاجرت است؛ این پلتفرم در زمینه‌های مختلف از جمله خرید و خدمات، آشنایی و دوستی، اقامت و همخانه، و دورهمی و ایونت‌ها فعالیت می‌کند. در حوزه خرید و خدمات، بهترین تخفیف‌ها و پیشنهادهای ویژه را ارائه می‌دهیم؛ در آشنایی و دوستی، به ایجاد ارتباطات اجتماعی جدید و معنادار کمک می‌کنیم؛ در اقامت و همخانه، گزینه‌های اقامتی و همخانه مناسب را فراهم می‌کنیم؛ و در دورهمی و ایونت‌ها، رویدادها و فعالیت‌های جذاب را کنار هم برگزار می‌کنیم. مهمتر از همه، تهرانتو کلاب متعهد به برنامه‌های توسعه فردی و رشد کسب‌وکارها است تا آینده‌ای بهتر برای هر فرد در این جامعه خلق کند.',
    contact_us: 'ارتباط با ما',
    address_title: 'آدرس:',
    phones: 'شماره های تماس:',
    whatsapp: 'واتس آپ',
    email: 'ایمیل',
    contact_desc: 'از مسیرهای زیر میتوانید با ما در ارتباط باشید',
};
