export const __FindingFriendDetailLang = {
    findingFriend: 'دوستیابی',
    text1: 'همچنان به دنبال دوست میگردم',
    text2: 'دوستیابی بر اساس شخصیت من',
    search: 'جست و جو',
    characterType: 'تیپ شخصیتی',
    retest: 'تست مجدد',
    name: 'نام:',
    yearBirth: 'سال تولد:',
    gender: 'جنسیت:',
    desiredGender: 'جنسیت مورد نظر:',
    telegramID: 'آیدی تلگرام:',
    city: 'شهر:',
    yourPictures: 'تصاویر شما',
    furtherInformation: 'اطلاعات تکمیلی:',
    personalInformation: 'اطلاعات شخصی',
    edit: 'ویرایش اطلاعات',
    artist: 'هنرمند',
    intuitive: 'شهودی',
    feeling: 'احساس',
    judgmental: 'قضاوتی',
    introverted: 'درونگرا',
    female: 'زن',
    male: 'مرد',
    buyLuck: 'خرید شانس',
    chance: 'تعداد شانس های آشنایی شما:',
    extroverted: 'برونگرا',
    sensing: 'رویاپرداز',
    intuition: 'واقع بین',
    thinking: 'منطقی',
    perceiving: 'برنامه ریز',
    perfect: 'سازگاری ایده آل',
    high: 'سازگاری بالا',
    moderate: 'سازگاری متوسط',
    low: 'سازگاری پایین',
    limitError:
        'باتوجه به خریدهای اخیر شما، امکان خرید تعداد شانس های مورد نظر شما وجود ندارد. لطفا تعداد شانس های مورد نظر خود را کاهش داده و یا چند روز دیگر اقدام نمایید.',
    buyChance: {
        title: 'خرید شانس',
        quantity: 'تعداد شانس مورد نظر',
        buy: 'خرید',
    },
};
