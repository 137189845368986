export const __SignUpLang = {
    signup_title: 'ایجاد حساب کاربری',
    signup_desc: 'برای ایجاد حساب کاربری نام، آدرس ایمیل و رمز عبور خود را وارد کنید.',
    email_title: 'آدرس ایمیل',
    password_title: 'رمز عبور',
    rules: 'ایجاد حساب به معنای پذیرش',
    rules_link: 'شرایط و قوانین تهرانتو',
    rules_verb: 'است.',
    login_account: 'ورود به حساب',
    create_account: 'ایجاد حساب کاربری',
};
