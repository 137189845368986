export const __ServiceFilterLang = {
    filters: 'فیلتر ها',
    delete: 'حذف',
    search_services: 'جستجو در خدمات ..',
    service_points: 'امتیاز خدمت',
    service_categories: 'دسته بندی خدمات',
    service_type: 'نوع خدمت',
    select: 'انتخاب کنید',
    cache_back: 'تخفیف',
    extra_service: 'خدمات و آفرهای ویژه',
    both: 'خدمات اضافی و تخفیف',
    brands: 'برند ها',
    search_brands: 'جستجو در برند ها',
    locationLabel: 'مناطق ارائه خدمات',
    locationPH: 'نام منطقه را وارد نمایید',
};
