export const __MbtiTestLang = {
    header: 'دوستیابی',
    return_btn: 'مرحله قبل',
    test_title: 'آزمون MBTI',
    next_page_btn: 'مرحله بعدی',
    finish_brn: 'پایان ازمون',
    agree: 'موافقم',
    disagree: 'مخالفم',
    require_error: 'پاسخ به سوالات الزامی است.',
    question1: 'شما تمایل دارید از رویدادهای شلوغ و پرهیاهو دوری کنید.',
    question2: 'شما دوست ندارید توجه‌ها را به خود جلب کنید و در پس‌زمینه باقی می‌مانید.',
    question3: 'شما در مهمانی‌ها با افراد مختلف زیادی صحبت می‌کنید.',
    question4: 'شما تمایل دارید دیگران را در فاصله نگه دارید و شناختن شما سخت است.',
    question5: 'شما تمایل دارید مکالمات را آغاز کنید و آن‌ها را ادامه دهید به جای اینکه به دیگران تکیه کنید.',
    question6: 'اگر آخر هفته را به تنهایی بگذرانید، خسته می‌شوید.',
    question7: 'شما در یک مکالمه وقتی به مباحث فلسفی می‌رسید، علاقه‌تان را از دست می‌دهید.',
    question8: 'شما تمایل دارید در خیال‌های خود گم شوید و خیال پردازی کنید.',
    question9: 'شما اغلب به معنی زندگی فکر می‌کنید.',
    question10: 'شما چیزها را همان‌طور که واقعاً هستند می‌بینید نه اینکه تصور کنید چه چیزی می‌توانند باشند.',
    question11: 'شما اغلب وقت خود را صرف کاوش ایده‌های جالب می‌کنید حتی اگر غیرواقعی باشند.',
    question12: 'شما خود را بیشتر یک واقع‌گرا می‌دانید تا یک آینده‌نگر.',
    question13: 'شما ترجیح می‌دهید برای محافظت از احساسات کسی دیپلماتیک باشید تا اینکه او را با حقیقت ناراحت کنید.',
    question14: 'اگر دوستتان درباره چیزی ناراحت باشد، غریزه اول شما حمایت عاطفی از اوست، نه تلاش برای حل مشکلش.',
    question15: 'شما معتقدید که صراحت مهم‌تر از محافظت از احساسات کسی است.',
    question16: 'شما اغلب در ارتباط با افرادی که اجازه می‌دهند احساساتشان آن‌ها را هدایت کند، مشکل دارید.',
    question17: 'شما به ندرت درباره احساسات و عواطف خود صحبت می‌کنید.',
    question18: 'وقتی نوبت به انتخاب‌های زندگی تغییر دهنده می‌رسد، بیشتر به قلبتان گوش می‌دهید تا به سرتان.',
    question19:
        'شما ترجیح می‌دهید هنگام سفر یک لیست کلی از ایده‌ها داشته باشید تا اینکه تمام جزئیات را برنامه‌ریزی کنید.',
    question20: 'شما دوست دارید کارها را فوراً انجام دهید تا اینکه منتظر بمانید.',
    question21: 'سبک کار شخصی شما نزدیک‌تر به انفجارهای ناگهانی انرژی است تا تلاش‌های سازمان‌یافته و مداوم.',
    question22: 'شما رویکردی دقیق و روش‌مند به زندگی دارید.',
    question23: 'شما اغلب تصمیمات را به صورت لحظه‌ای می‌گیرید.',
    question24: 'شما اغلب فراموش می‌کنید که چیزها را به جای مناسب خود برگردانید.',
    question25: 'شما به ندرت زمان زیادی را صرف فکر کردن به اشتباهات گذشته می‌کنید.',
    question26: 'شما اغلب بعد از یک مکالمه به این فکر می‌کنید که چه چیزی باید گفته‌اید.',
    question27: 'اگر اشتباهی مرتکب شوید، تمایل دارید به خودتان، توانایی‌هایتان یا دانش‌تان شک کنید.',
    question28: 'احساسات شما بیشتر از آنکه شما آن‌ها را کنترل کنید، بر شما کنترل دارند.',
    question29: 'شما قادر به کنترل هوس‌های خود هستید.',
    question30: 'حال و هوای شما می‌تواند بسیار سریع تغییر کند.',
    error: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    success: 'تست MBTI با موفقیت انجام شد.',
};
