import styles from './gallery.module.scss';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { nextBrowser, useBP } from 'scripts';
import Image from 'next/image';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { AttachmentType, LanguageEnum } from 'types';
import { TbCamera } from 'react-icons/tb';
import { GlobalContext } from 'context';

function GalleryComp({ images, link }: { images?: AttachmentType[]; link: string }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const bp = useBP();
    const language = useContext(GlobalContext).langText.lang;
    const isRtl = language === LanguageEnum.fa;
    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        rtl: isRtl,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    const onLinkClick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        nextBrowser.window?.open(link);
    };

    useEffect(() => {
        instanceRef.current?.update();
        if (bp) setLoading(false);
    }, [bp?.bp]);

    return (
        <div className={`${styles['navigation-wrapper']}`}>
            {loading ? (
                <div className={`${styles['loading']} d-flex center-content`}>
                    <Spin className="d-flex-i center-content" />
                </div>
            ) : (
                <div ref={sliderRef} className={`${styles['slider']} keen-slider`}>
                    {images?.length ? (
                        images.map((image, index) => (
                            <a
                                key={index}
                                href={link}
                                className={`keen-slider__slide ${styles['slide-item']}`}
                                onClick={onLinkClick}
                            >
                                {['MP4', 'WebM', 'AVI', 'WMV', 'MKV'].find((i) =>
                                    image.file.toUpperCase().endsWith(i),
                                ) ? (
                                    <video autoPlay={true} muted={true} controls={false} loop={true} src={image.file} />
                                ) : (
                                    <>
                                        <Image
                                            src={image.file}
                                            alt={image.alt ?? ''}
                                            width={220}
                                            height={171}
                                            className="d-pre-lg-none"
                                        />
                                        <Image
                                            src={image.file}
                                            alt={image.alt ?? ''}
                                            width={500}
                                            height={256}
                                            className="d-pre-lg-block w-100"
                                        />
                                    </>
                                )}
                            </a>
                        ))
                    ) : (
                        <div className={`w-100 h-100 center-content ${styles['empty-state']}`}>
                            <TbCamera className="t-dark3" size={48} />
                        </div>
                    )}
                </div>
            )}
            <div className={`${styles['dots']} d-flex`}>
                {loaded &&
                    instanceRef.current &&
                    images?.length &&
                    images.map((i, index) => (
                        <span
                            key={index}
                            onClick={() => {
                                instanceRef.current?.moveToIdx(index);
                            }}
                            className={`${styles['dot']}  ${currentSlide === index ? styles['active'] : ''}`}
                        />
                    ))}
            </div>
        </div>
    );
}

export default GalleryComp;
