export const __EventsFilterLang = {
    filters: 'فیلتر ها',
    delete: 'حذف',
    category: 'دسته بندی رویداد ها',
    all_categories: 'تمام دسته بندی ها',
    date: 'تاریخ برگزاری',
    entrance_fee: 'هزینه ورود',
    capacity: 'ظرفیت',
    placeholder: 'جستجو در رویداد ها ...',
    selected_date: 'انتخاب تاریخ',
    select: 'انتخاب کنید',
    enter: 'وارد کنید',
    filter: 'اعمال فیلتر',
};
