export const __BlogLang = {
    title: 'وبلاگ تهرانتو',
    desc: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی.',
    search: 'جستجو در وبلاگ ..',
    ordering: {
        index: 'مرتب سازی',
        newest: 'جدیدترین',
        mostViews: 'پربازدید ترین',
        mostComments: 'بیشترین نظر',
    },
    result: 'نتیجه',
    author: 'نویسنده',
    errorMessage: 'درخواست با خطا مواجه شد.',
    empty: 'هیچ پست بلاگی وجود ندارد. لطفا بعدا مراجعه فرمایید.',
};
