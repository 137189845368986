export const __ProfileLang = {
    user_profile: 'پروفایل کاربر',
    your_image: 'تصویر شما',
    image_desc: 'به عنوان تصویر شما در نظرات و بخش های دیگر نمایش داده میشود.',
    upload_image: 'آپلود تصویر جدید',
    save: 'ذخیره',
    account_statistics: 'آمار حساب',
    purchases: 'خرید ها',
    free_services: 'خدمات و آفرهای ویژه',
    your_profit: 'سود شما',
    personal_information: 'اطلاعات شخصی',
    confirmation: 'تایید',
    edit: 'ویرایش',
    full_name: 'نام کامل',
    full_name_ph: 'لطفا نام خود را با حروف انگلیسی وارد کنید',
    email: 'آدرس ایمیل',
    eTransferEmail: 'آدرس ایمیل بانکی',
    email_ph: 'لطفا آدرس ایمیل خود را وارد نمایید',
    phone_number: 'شماره تماس',
    password: 'رمز عبور',
    change_password: 'تغییر رمز عبور',
    error: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    emailIncorrect: 'لطفا یک ایمیل صحیح وارد نمایید',
    emailRequired: 'لطفا ایمیل خود را وارد کنید',
};
