import { BuildingTypeEnum, StayTypeEnum } from '@/types/index';
import { __HousingFilterLang } from './housingFilter.lang';

export const __BuildingTypeData = [
    { label: 'Condo', value: BuildingTypeEnum.condo.toString() },
    { label: 'Rental', value: BuildingTypeEnum.rental.toString() },
    { label: 'Townhouse', value: BuildingTypeEnum.townhouse.toString() },
    { label: 'Detached House', value: BuildingTypeEnum.detached_house.toString() },
    { label: 'Basement', value: BuildingTypeEnum.basement.toString() },
];

export const __StayTypeData = (lang: typeof __HousingFilterLang) => [
    { label: lang.normal_room, value: StayTypeEnum.normal_room.toString() },
    { label: lang.master_room, value: StayTypeEnum.master_room.toString() },
    {
        label: lang.small_room,
        value: StayTypeEnum.small_room.toString(),
    },
    { label: lang.canape, value: StayTypeEnum.canape.toString() },
    { label: lang.independent_common, value: StayTypeEnum.independent_unit_common_entrance.toString() },
    { label: lang.independent_independent, value: StayTypeEnum.independent_unit_independent_entrance.toString() },
];
