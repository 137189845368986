'use client';
import React, { useContext, useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styles from './slider.module.scss';
import { useBP } from 'scripts';
import { BPEnum, LanguageEnum } from 'types';
import { GlobalContext } from 'context';
import { Spin } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { __SliderPropsType } from './slider.type';

export function __SliderWrapperComp<R>(props: __SliderPropsType<R>) {
    const data = props.layout ?? [
        { max_width: BPEnum.SM, perView: 1.25 },
        { max_width: BPEnum.MD, perView: 4 },
        { max_width: BPEnum.LG, perView: 4 },
        { max_width: BPEnum.XL, perView: 5.25 },
        { max_width: BPEnum.XXL, perView: 5.25 },
    ];
    const context = useContext(GlobalContext).langText;
    const lang = context.slider;
    const spacing = 20; // space of slides
    const bp = useBP();
    const rtl = context.lang === LanguageEnum.fa;
    const perView = data.find((i) => i.max_width === bp?.bp)?.perView;
    const items =
        props.items.length === Math.ceil(perView ?? 0) && !props.noLoop
            ? [...props.items, ...props.items]
            : props.items;
    const slideAble = props.items.length > (perView ?? 0);
    const sortedData = data.sort((a, b) => b.max_width - a.max_width);
    const [loading, setLoading] = useState(true);
    const [sliderLoading, setSliderLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const loop = props.noLoop ? false : slideAble;

    const [sliderObj, sliderRef] = useKeenSlider<HTMLDivElement>({
        rtl,
        loop,
        slides: {
            perView: slideAble ? sortedData[0].perView : Math.floor(sortedData[0].perView),
            spacing: sortedData[0].spacing ?? spacing,
            origin: slideAble ? (sortedData[0].origin !== undefined ? sortedData[0].origin : props.origin) : 'auto',
        },
        created: () => setSliderLoading(false),
        slideChanged: (s) => {
            const newItem = s.track.details.rel;
            setCurrentSlide(newItem);
            if (props.onSlideChange) props.onSlideChange(newItem);
        },
        animationEnded: (s) => {
            if (props.activeSlide !== undefined) {
                const { rel, progress } = s.track.details;
                if (rel === 0 && progress !== 0) s.update();
            }
        },
        breakpoints: sortedData.slice(1).reduce(
            (o, key) => ({
                ...o,
                [`(max-width: ${key.max_width}px)`]: {
                    slides: {
                        perView: slideAble ? key.perView : Math.floor(key.perView),
                        spacing: key.spacing ?? spacing,
                        origin: slideAble ? (key.origin !== undefined ? key.origin : props.origin) : 'auto',
                    },
                },
            }),
            {},
        ),
    });

    const nextProps = sliderRef.current
        ? {
              onClick: () => sliderRef.current?.next(),
              className: `${styles[`${rtl ? 'left' : 'right'}-arrow`]} ${currentSlide === sliderRef.current.track.details?.slides.length - 1 && !loop ? styles['disable'] : ''}`,
          }
        : {};

    const prevProps = {
        onClick: () => sliderRef.current?.prev(),
        className: `${styles[`${rtl ? 'right' : 'left'}-arrow`]} ${currentSlide === 0 && !loop ? styles['disable'] : ''}`,
    };

    const generateRows = () => {
        const newList: R[][] = [];
        const rows = !props.rows || props.rows < 2 ? 1 : props.rows;
        for (let i = 0; i < items.length; i += rows) {
            newList.push(items.slice(i, i + rows));
        }
        return newList;
    };

    useEffect(() => {
        sliderRef.current?.update();
        if (bp) setLoading(false);
    }, [bp?.bp]);

    useEffect(() => {
        if (!sliderLoading) sliderRef.current?.update();
    }, [sliderLoading]);

    useEffect(() => {
        if (props.activeSlide !== undefined && sliderRef.current) sliderRef.current.moveToIdx(props.activeSlide);
    }, [props.activeSlide]);

    const slide = (
        <div
            ref={sliderObj}
            className={`${slideAble ? 'keen-slider' : 'd-flex'} ${styles['no-overflow']} ${slideAble ? '' : styles['slider-limited-width']}`}
            style={slideAble ? undefined : { gap: sortedData[0].spacing ?? spacing }}
        >
            {!props.rows || props.rows < 2
                ? items.map((item, index) => (
                      <props.child
                          item={item}
                          key={index}
                          className={`${slideAble ? 'keen-slider__slide' : ''} ${styles['no-overflow']} ${props.innerClassName ? props.innerClassName(index) : ''}`}
                      />
                  ))
                : generateRows().map((item, index) => (
                      <div
                          className={`${slideAble ? 'keen-slider__slide' : ''} flex-column ${styles['no-overflow']}`}
                          style={{ gap: spacing }}
                          key={index}
                      >
                          {item.map((innerItem, index) => (
                              <props.child
                                  item={innerItem}
                                  key={index}
                                  className={props.innerClassName ? props.innerClassName(currentSlide) : ''}
                              />
                          ))}
                      </div>
                  ))}
        </div>
    );

    if (loading)
        return (
            <div
                className={`center-content d-flex-i ${props.className ?? ''}`}
                style={{ minHeight: props.loadingHeight }}
            >
                <Spin className={`center-content d-flex-i`} />
            </div>
        );
    if (items.length === 0)
        return (
            <p className={`${styles['slider-no-data']} w-100 t-p1 center-content ${props.className}`}>{lang.noData}</p>
        );
    return slideAble ? (
        <div
            className={`${styles['slider-wrapper']} ${props.showArrowOnHover ? styles['show-hide-arrows'] : ''} ${props.className}`}
        >
            {slide}
            {!props.hideArrow ? (
                <>
                    <TbArrowLeft {...(rtl ? { ...nextProps } : { ...prevProps })} />
                    <TbArrowRight {...(rtl ? { ...prevProps } : { ...nextProps })} />
                </>
            ) : null}
            {!props.hideShadow ? <span className={styles['shadow']} /> : null}
            {!props.hideShadowSecond ? <span className={styles['second-shadow']} /> : null}
        </div>
    ) : (
        <div className={`ph-3 w-100 ${props.origin === 'center' ? 'center-content-x' : ''} ${props.className}`}>
            {slide}
        </div>
    );
}
