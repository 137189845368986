export const __LandingLang = {
    viewAll: 'مشاهده همه',
    services: 'خرید و خدمات',
    comments: 'نظرات مشتریان',
    brands: 'خدمات ویژه تهرانتو کلاب',
    development: 'خدمات تهرانتو کلاب',
    tehrantoProcess: 'تهرانتو تا کنون',
    faq: 'سوالات پر تکرار',
    support: 'ارتباط با پشتیبانی',
    faqDesc:
        'پاسخ سوالات خود را در بخش سوالات متداول ما بیابید. ما به سوالات رایج درباره محصولات، خدمات و سیاست‌های خود پاسخ می‌دهیم تا اطلاعات سریع و واضحی را در اختیار شما قرار دهیم.',
    notFountQuestion: 'پاسخ سوال خود را پیدا نکردید؟',
    headerTitle: 'تهرانتو کلاب، بهترین فرصت ها مطابق با نیاز شما در بین ایرانیان مقیم کانادا',
    headerDesc: ['خرید و خدمات', 'آشنایی و دوستی', 'دورهمی و ایونت', 'اقامت و همخانه'],
    moreInfo: 'اطلاعات بیشتر',
};
