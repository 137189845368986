export const __PageBuilderLang = {
    close: 'بستن',
    more: 'سایر المان ها',
    primaryButton: 'دکمه اصلی',
    primaryButtonDesc: 'دکمه با استایل تهرانتو',
    desc: 'توضیحات',
    descDesc: 'باکس توضیح به همراه عنوان',
    videoSec: 'سکشن ویدیویی',
    videoSecDesc: 'ویدیو به همراه توضیح',
};
